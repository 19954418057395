import React from 'react'
import './header.css'

function Header() {
  return (
    <header className='header-container'>
        <h1>MCQ Questions</h1>
    </header>
  )
}

export default Header